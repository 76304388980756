<template>
  <div
    class="chart"
    :class="{ 'padding-top-zero': config.title }"
  >
    <p
      v-if="config.title"
      class="chart-title"
      :class="{ underline: config.underlinetext }"
    >
      {{ config.title }}
      <span class="chart-titleImp">
        {{ config.titleImp }}</span
      >
    </p>
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'app-home-chart',

  props: ['config'],

  data() {
    return {
      id: uuid(),
    };
  },

  mounted() {
    Chart.plugins.unregister(ChartDataLabels);
    const ctx = document.getElementById(this.id);
    new Chart(ctx, this.config);
  },
};
</script>

<style>
.chart {
  background-color: white;
  padding: 24px;
  border: 1px solid #e8e8e8;
}
.padding-top-zero {
  padding-top: 0px;
}
.chart-title {
  font-size: 16px;
  color: darkslategrey;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  padding-bottom: 3px;
}

.chart-titleImp {
  color: #363a52;
  font-weight: 700;
  font-size: 20px;
}

.underline {
  text-decoration: none;
  position: relative;
}

.underline:after {
  position: absolute;
  content: '';
  height: 2px;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 50%;
  background: green;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.underline:hover:after {
  width: 80%;
  background: orange;
}
</style>
